<script setup>
import { ref } from 'vue'
import TMD from '@/assets/images/TMD.png'
import logotmd from '@/assets/images/logotmd.png'
import fb_logo from '@/assets/images/fb_logo.svg'
import instagram_logo from '@/assets/images/footer/instagram.svg'
import tiktok_logo from '@/assets/images/footer/tiktok-icon.svg'
import { sendEmailPub } from '@/services/mail'

const goToSocial = (type) =>  {
  if (type === 0) {
    window.open('https://www.facebook.com/The-Music-Distribution-100429958933808')
  } else if (type === 1) {
    window.open('https://www.tiktok.com/@tmd.oficial')
  } else {
    window.open('https://www.instagram.com/themusicdistribution___/')
  }
}
let mailSubscriber = ''
const isSent = ref(true)
const sendMail = () => {
  console.log(mailSubscriber)
  let data = new FormData()
  data.append('email', mailSubscriber)
  sendEmailPub(data).then((response) => {
    
  })
  isSent.value = false
}
</script>
<template>
  <footer class="footer">
    <div v-if="isSent" style="margin: 48px 0">
      <h3>SUSCRÍBETE A LA COMUNIDAD</h3>
      <p class="footer-subtitle">
        Suscríbete y entérate de las noticias del mundo musical
      </p>
      <div style="display: flex; justify-content: center">
        <div class="footer-custom-input text-white">
          <input v-model="mailSubscriber" placeholder="tucorreo@correo.com" class="text-white" />
          <button type="button" @click="sendMail" class="footer-input-button">SUSCRIBIRME</button>
        </div>
      </div>
    </div>
    <div v-if="!isSent" style="margin: 48px 0">
      <h3>SUSCRÍBETE A LA COMUNIDAD</h3>
      <p class="footer-subtitle">
        Se ha enviado tu correo.
      </p>
    </div>
    <!-- second section -->
    <div class="footer-container-second-s">
      <div class="container-logos">
        <div class="logo-tmd">
          <img :src="logotmd" alt="logo" style="height: 43px; width: 43px" />
          <img :src="TMD" alt="logo" style="height: 21px; width: 220px" />
        </div>
        <div class="div-logos">
          <img :src="fb_logo" @click="goToSocial(0)" alt="facebook logo" class="footer-icons div-logo-hover" />
          <img :src="tiktok_logo" @click="goToSocial(1)" alt="tiktok logo" class="footer-icons div-logo-hover" />
          <img :src="instagram_logo" @click="goToSocial(2)" alt="instagram logo" class="footer-icons div-logo-hover" />
        </div>
      </div>

      <div class="footer-texts">
        <!--div>
          <h5>SOBRE</h5>
          <p>FAQ</p>
          <p>Tutoriales</p>
          <p>Blog</p>
        </div-->
        <div>
          <h5>SERVICIOS EMPRESARIALES</h5>
          <p><a class="a-href" href="/tmd-pro-access/servicios-empresariales/gestion-catalogos">Gestión de catálogos</a>
          </p>
          <p><a class="a-href" href="/tmd-pro-access/servicios-empresariales/distribucion-entrega">Distribución y
              entrega</a></p>
          <p><a class="a-href" href="/tmd-pro-access/servicios-empresariales/informes-ingresos">Informes de Ingresos</a>
          </p>
        </div>
        <div>
          <h5>SERVICIOS DE WEB3</h5>
          <p><a class="a-href" href="/tmd-pro-access/contratos-inteligentes/servicios-web3">Contratos inteligentes</a>
          </p>
        </div>
        <!--div>
          <h5>SERVICIOS DE DATOS</h5>
          <p><a class="a-href" href="/tmd-pro-access/servicios-datos/integracion-datos">Integraciones</a></p>
          <p><a class="a-href" href="/tmd-pro-access/servicios-datos/almacenamiento-datos">Almacenes de datos</a></p>
          <p><a class="a-href" href="/tmd-pro-access/servicios-datos/analitica">Analítica</a></p>
        </div-->
      </div>
      <div></div>
    </div>
    <div class="footer-container-third">
      <p>The Music Distribution © 2024 | Todos los derechos reservados</p>
      <p><a class="a-href" href="https://distribucion.themusicdistribution.com/about/privacy_policy"> Políticas y
          Condiciones</a></p>
    </div>
  </footer>
</template>
<style lang="scss" scoped >
.a-href {
  text-decoration: none;
  color: #FFF;
}
.a-href:hover {
  text-decoration: none;
  color: #fd7e14;
}
.div-logo-hover:hover {
  cursor: pointer;
}
</style>
